import { pauseAllMedia, removeTrapFocus, trapFocus, type uCoastWindow } from '@/scripts/setup'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import {
	closeAllModals,
	closestOptional,
	getAttributeOrThrow,
	qsOptional,
	qsRequired,
	targetRequired,
} from '@/scripts/functions'
import { type DeferredMedia } from '@/scripts/media/deferred-media'
import { type KlaviyoForm, type KlaviyoOosForm } from '@/scripts/forms/klaviyo-form'

declare let window: uCoastWindow

export class Modal extends UcoastEl {
	static htmlSelector = 'modal-dialog'
	closeButton: HTMLButtonElement
	moved: boolean
	openedBy?: HTMLElement
	constructor() {
		super()
		this.closeButton = qsRequired('[id^="ModalClose-"]', this)
		this.closeButton.addEventListener('click', this.hide.bind(this, false))
		this.addEventListener('keyup', (event) => {
			if (event.code && event.code.toUpperCase() === 'ESCAPE') this.hide()
		})
		if (this.classList.contains('media-modal')) {
			this.addEventListener('pointerup', (event) => {
				if (event.pointerType !== 'mouse') return
				const closest = closestOptional(
					targetRequired(event),
					'deferred-media, product-model, klaviyo-popup'
				)
				if (closest) return
				this.hide()
			})
		} else {
			this.addEventListener('click', (event) => {
				if (event.target === this) this.hide()
			})
		}
		this.moved = false
	}

	override connectedCallback() {
		super.connectedCallback()
		if (this.moved) return
		this.moved = true
		document.body.appendChild(this)
	}

	show(opener?: HTMLButtonElement, pauseMedia = true) {
		closeAllModals()
		this.openedBy = opener
		const popup = qsOptional<DeferredMedia>('.template-popup', this)
		document.body.classList.add('overflow-hidden')
		this.setAttribute('open', '')
		if (popup) popup.loadContent()
		trapFocus(this, qsOptional('[role="dialog"]', this))
		if (pauseMedia) pauseAllMedia(this)
	}

	hide() {
		document.body.classList.remove('overflow-hidden')
		document.body.dispatchEvent(new CustomEvent('modalClosed'))
		this.removeAttribute('open')
		removeTrapFocus(this.openedBy)
		pauseAllMedia(this)
	}
}

export class KlaviyoPopup extends Modal {
	static override htmlSelector = 'klaviyo-popup'
	overlay: HTMLButtonElement
	klaviyoForm: KlaviyoForm
	openAfter: number
	cacheKey: string
	constructor() {
		super()
		this.overlay = qsRequired('[data-uc-overlay]', this)
		this.overlay.addEventListener('click', this.hide.bind(this, [true]))
		this.klaviyoForm = qsRequired('klaviyo-form', this)
		this.openAfter = parseInt(getAttributeOrThrow('data-uc-open-after', this)) * 1000
		this.cacheKey = getAttributeOrThrow('data-uc-cache-key', this)
	}

	initializePopup() {
		if (this.isKeyDisabled() && window.demoMode === undefined) return
		if (window.Shopify.customerPrivacy) {
			const userTrackingConsent = window.Shopify.customerPrivacy.getTrackingConsent()
			const preventBanner =
				userTrackingConsent === 'no_interaction' || window.demoMode === true
			if (!preventBanner && !this.hasAttribute('open')) {
				window.setTimeout(() => {
					this.show()
				}, this.openAfter)
			} else {
				window.klaviyoPopupWaitingForConsent = true
			}
		} else {
			window.setTimeout(() => {
				this.show()
			}, this.openAfter)
		}
	}

	override connectedCallback() {
		if (window.Shopify.loadFeatures) {
			window.Shopify.loadFeatures(
				[
					{
						name: 'consent-tracking-api',
						version: '0.1',
					},
				],
				this.initializePopup.bind(this)
			)
		}
	}

	override show(opener?: HTMLButtonElement) {
		this.removeAttribute('style')
		window.setTimeout(() => {
			super.show(opener, false)
		}, 1)
	}

	override hide() {
		super.hide()
		this.disableKey()
	}

	isKeyDisabled() {
		const disabledKey = window.localStorage.getItem('disable_klaviyo')
		if (!disabledKey) return false
		return disabledKey === this.cacheKey
	}

	disableKey() {
		window.localStorage.setItem('disable_klaviyo', this.cacheKey)
	}
}

export class NotifyMe extends Modal {
	static override htmlSelector = 'notify-me'
	overlay: HTMLButtonElement
	klaviyoOosForm: KlaviyoOosForm
	titleEl: HTMLElement
	variantInput: HTMLInputElement
	constructor() {
		super()
		this.overlay = qsRequired('[data-uc-overlay]', this)
		this.overlay.addEventListener('click', this.hide.bind(this))
		this.klaviyoOosForm = qsRequired('klaviyo-oos', this)
		this.titleEl = qsRequired('[data-uc-notify-me-title]', this)
		this.variantInput = qsRequired('input[name="variant"]', this)
	}

	initialize(opener: HTMLButtonElement) {
		const title = getAttributeOrThrow('data-uc-product-title', opener)
		const variantId = getAttributeOrThrow('data-uc-variant-id', opener)
		this.variantInput.value = variantId
		this.titleEl.innerHTML = title
		this.klaviyoOosForm.setAttribute('data-uc-oos-variant', variantId)
		this.show(opener)
	}

	override show(opener?: HTMLButtonElement) {
		this.removeAttribute('style')
		window.setTimeout(() => {
			super.show(opener, false)
		}, 1)
	}
	override connectedCallback() {}
}

export class ModalOpener extends UcoastEl {
	static htmlSelector = 'modal-opener'
	button: HTMLButtonElement
	selector: string
	modal?: Modal
	disableMobileZoom: boolean
	constructor() {
		super()
		this.selector = getAttributeOrThrow('data-modal', this)
		this.button = qsRequired('button', this)
		this.modal = qsOptional(this.selector)
		this.disableMobileZoom = this.hasAttribute('data-uc-disable-mobile-zoom')
		this.button.addEventListener('click', () => {
			if (this.modal && !(this.disableMobileZoom && window.innerWidth <= 989)) {
				this.modal.show(this.button)
			}
		})
	}
	assignModal() {
		const modalEl = qsOptional<Modal>(this.selector)
		if (modalEl) this.modal = modalEl
	}
}
